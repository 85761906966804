import * as THREE from "three";
import * as _ from "underscore";
import TestMesh from "src/webgl/TestMesh";
import CrumblyMesh from "src/webgl/CrumblyMesh";

// Random integer from <low, high> interval
export function randInt(low: number, high: number): number {
return low + Math.floor(Math.random() * (high - low + 1));
}

// Javascript mod fix
export function mod(n: number, m: number): number {
return ((n % m) + m) % m;
}

export type Assets = {
fonts?: any;
textures?: any;
videos?: any;
};

export interface Uniforms {
[key: string]: THREE.IUniform;
}

export interface HTMLExtBase {
    [key: string]: HTMLElement;
}

export interface HTMLExt {
    [key: string]: HTMLElement | gsap.core.Timeline;
}

export interface HTMLExtData {
    [key: string]: HTMLElement | gsap.core.Timeline | number;
}

export interface IFunction {
    [key: string]: (elem: HTMLElement, mesh?: CrumblyMesh, sceneName?: string) => (time: number, rect: any) => void;
}

export interface IFunctionTest {
    [key: string]: (elem: HTMLElement, mesh?: TestMesh) => (time: number, rect: any) => void;
}

export type PageData = {
    section: (HTMLElement & HTMLExt), 
    elem: HTMLElement,
    ctx: CanvasRenderingContext2D, 
    fn: (time: number, rect: any) => void
}

export interface Variables {
easeVar: number;
}

export class AssetsStore implements Assets {
constructor() {
    this.fonts = {};
    this.glyphs = {};
    this.textures = {};
    this.videos = {};
}

fonts?: any;
glyphs?: any;
textures?: any;
videos?: any;
}

export function shuffleData(data: any[], num)
{
    var vals = _.sortBy(
                    _.keys(data),
                    function(k)
                    {
                        return (Math.random() * 3) - 1;
                    }
        ),                    
        results = [],
        val;
        var properties = _.keys(data[0]);
    if (num > vals.length) {
        throw new Error('Impossible to retrieve more values than exist');
    }
    while (results.length < num) {
        val = vals.pop();
        results.push({[properties[0]]: data[results.length][properties[0]], [properties[1]]: data[val][properties[1]]});
    }
    return results;
}

export function isImage(i) {
    return i instanceof HTMLImageElement;
}

/**
 * Clamps a number. Based on Zevan's idea: http://actionsnippet.com/?p=475
 * params: val, min, max
 * Author: Jakub Korzeniowski
 * Agency: Softhis
 * http://www.softhis.com
 */
export function clamp(x: number, minVal: number, maxVal: number){
    return Math.min(Math.max(x, minVal), maxVal);
}

export function mix(x: number, y: number, a: number) {
    return x * (1 - a) + y * a;
}

export function calculateDistance(elemRect: DOMRect, mouseX: number, mouseY: number) {
    return Math.floor(Math.sqrt(Math.pow(mouseX - (elemRect.left + (elemRect.width / 2)), 2) + Math.pow(mouseY - (elemRect.top + (elemRect.height / 2)), 2)));
}

export function cover(texture: THREE.Texture, aspect: number) {
    var imageAspect = texture.image.width / texture.image.height;
    if ( aspect < imageAspect ) {
        texture.matrix.setUvTransform( 0, 0, aspect / imageAspect, 1, 0, 0.5, 0.5 );
    } else {
        texture.matrix.setUvTransform( 0, 0, 1, imageAspect / aspect, 0, 0.5, 0.5 );
    }
}

export function coverTransformation(texture: THREE.Texture, aspect: number): THREE.Matrix3 {
    if (isEmptyObject(texture) || isEmptyObject(texture.image)) {
        return new THREE.Matrix3();
    }

    if (isImage(texture.image))
        var imageAspect = texture.image.width / texture.image.height;
    else 
        var imageAspect = texture.image.videoWidth / texture.image.videoHeight;

    if ( aspect < imageAspect ) {
        texture.matrix.setUvTransform( 0, 0, aspect / imageAspect, 1, 0, 0.5, 0.5 );
    } else {
        texture.matrix.setUvTransform( 0, 0, 1, imageAspect / aspect, 0, 0.5, 0.5 );
    }

    return texture.matrix;
}

export function makeUVs(v0: THREE.Vector3, v1: THREE.Vector3, v2: THREE.Vector3, v3: THREE.Vector3, transformMatrix: THREE.Matrix3, bbox: THREE.Box3, bbox_max_size: THREE.Vector3, diff: THREE.Vector2): {uv0: THREE.Vector2, uv1: THREE.Vector2, uv2: THREE.Vector2, uv3: THREE.Vector2} {

    //pre-rotate the model so that cube sides match world axis
    v0.applyMatrix3(transformMatrix);
    v1.applyMatrix3(transformMatrix);
    v2.applyMatrix3(transformMatrix);
    v3.applyMatrix3(transformMatrix);

    //var diff = new THREE.Vector2(offset.x, offset.y);
    //diff.applyMatrix3(transformMatrix);

    //get normal of the face, to know into which cube side it maps better

    let uv0 = new THREE.Vector2();
    let uv1 = new THREE.Vector2();
    let uv2 = new THREE.Vector2();
    let uv3 = new THREE.Vector2();
    
    // xy mapping    
    uv0.x = (v0.x - (bbox.min.x + diff.x /2) ) / bbox_max_size.x;
    uv0.y = (v0.y - (bbox.min.y + diff.y /2) + diff.y) / bbox_max_size.y;

    uv1.x = (v1.x - (bbox.min.x + diff.x /2) + diff.x) / bbox_max_size.x;
    uv1.y = (v1.y - (bbox.min.y + diff.y /2) + diff.y) / bbox_max_size.y;

    uv2.x = (v2.x - (bbox.min.x + diff.x /2)) / bbox_max_size.x;
    uv2.y = (v2.y - (bbox.min.y + diff.y /2)) / bbox_max_size.y;

    uv3.x = (v3.x - (bbox.min.x + diff.x /2) + diff.x) / bbox_max_size.x;
    uv3.y = (v3.y - (bbox.min.y + diff.y /2)) / bbox_max_size.y;


    return {
      uv0: uv0,
      uv1: uv1,
      uv2: uv2,
      uv3: uv3
    };
};

/**
 * Returns the element height including margins
 * @param element - element
 * @returns {number}
 */
export function outerHeight(element: HTMLElement) {
    const height = element.offsetHeight,
        style = window.getComputedStyle(element)

    return ['top', 'bottom']
        .map(side => parseInt(style[`margin-${side}`]))
        .reduce((total, side) => total + side, height)
}


/**
 * Linear interpolation 
 * the linear interpolation between a and b for the parameter n
 * (or extrapolation, when n is outside the range [0,1])
 * @param a - first value
 * @param b - second value
 * @param n - parameter in the the range [0,1]
 * @returns {number}
 */
export const lerp = (a: number, b: number, n: number) => (1 - n) * a + n * b;

/**
 * Gets the mouse position
 * @param e 
 */
export function getMousePos(e: MouseEvent | any) {
    let posx = 0;
    let posy = 0;
    if (!e) e = window.event;
    if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
    }
    else if (e.clientX || e.clientY)    {
        posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }

    return { x : posx, y : posy }
};

export const calcWinsize = () => {
    return {width: window.innerWidth, height: window.innerHeight};
};

export const distance = (x1,y1,x2,y2) => {
    var a = x1 - x2;
    var b = y1 - y2;

    return Math.hypot(a,b);
}

export function distanceToBorder(mX: number, mY: number, elementRect: DOMRect){
	let from = {x:mX, y:mY},
		ny1 = elementRect.top,//top
		ny2 = elementRect.bottom,//bottom
		nx1 = elementRect.left,//left
		nx2 = elementRect.right,//right
		maxX1 = Math.max(mX, nx1),
		minX2 = Math.min(mX, nx2),
		maxY1 = Math.max(mY, ny1),
		minY2 = Math.min(mY, ny2),
		intersectX = minX2 >= maxX1,
		intersectY = minY2 >= maxY1,
		to = {
			x: intersectX ? mX : nx2 < mX ? nx2 : nx1,
			y: intersectY ? mY : ny2 < mY ? ny2 : ny1
		},
		distX = to.x - from.x,
		distY = to.y - from.y,
		hypot = (distX**2 + distY**2)**(1/2);
	return Math.floor(hypot);//this will output 0 when next to your element.
}

export function getBoundingRect(element: HTMLElement) {

    var style = window.getComputedStyle(element); 
    var margin = {
        left: parseInt(style["margin-left"]),
        right: parseInt(style["margin-right"]),
        top: parseInt(style["margin-top"]),
        bottom: parseInt(style["margin-bottom"])
    };
    var padding = {
        left: parseInt(style["padding-left"]),
        right: parseInt(style["padding-right"]),
        top: parseInt(style["padding-top"]),
        bottom: parseInt(style["padding-bottom"])
    };
    var border = {
        left: parseInt(style["border-left"]),
        right: parseInt(style["border-right"]),
        top: parseInt(style["border-top"]),
        bottom: parseInt(style["border-bottom"])
    };
    
    
    var rect = element.getBoundingClientRect();
    //var bobyRect = document.body.getBoundingClientRect();
    rect = {   
        ...rect,
        //left: rect.left - margin.left - bobyRect.left,
        left: rect.left - margin.left,
        right: rect.right - margin.right - padding.left - padding.right,
        //top: rect.top - margin.top - bobyRect.top,
        top: rect.top - margin.top,
        bottom: rect.bottom - margin.bottom - padding.top - padding.bottom - border.bottom
    };
    rect.width = rect.right - rect.left;
    rect.height = rect.bottom - rect.top;
    return rect;
    
};

export function isEmptyObject(obj){
    for(var i in obj) return false; 
    return true;
}

export function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}

export function isEmpty(str: string) {
    return (!str || 0 === str.length);
}

/**
 * Manage "will-transform" class or will-change style property
 * @param elem - targeted element
 * @param isAdding - adding or removing property
 * @param param - string value of will-change proprty (optional)
 * @returns {void}
 */
export function willChange(elem: HTMLElement, isAdding: boolean, param?: string) {
    if (isAdding) {
      if (isEmpty(param)) {
        elem.classList.add("will-transform");
      } else {
        elem.style.willChange = param;
      }
    }
    else {
      if (isEmpty(param)) {
        elem.classList.remove("will-transform");
      } else {
        elem.style.willChange = param;
      }
    } 
  }

export function drawBoundsForElement(rect: DOMRect) {
            //test
            var div = document.createElement("div");
            div.style.background = "transparent";
            div.style.border = "thin solid red";
            div.style.left = rect.left.toString() + 'px';
            div.style.top = rect.top.toString() + 'px';
            div.style.width = (rect.right - rect.left).toString() + 'px';
            div.style.height = (rect.bottom - rect.top).toString() + 'px';
            div.style.position = "absolute"
            document.querySelector('body').appendChild(div);
}

/**
 *
 * @param {Array} texturesSources - List of Strings that represent texture sources
 * @returns {Array} Array containing a Promise for each source 
 */
function getTextures (texturesSources) {
    const loader = new THREE.TextureLoader()
    return texturesSources.map(textureSource => {
        return new Promise((resolve, reject) => {
            loader.load(
                textureSource,
                texture => resolve(texture),
                undefined, // onProgress callback not supported from r84
                err => reject(err)
            )
        })
    })
}

function loadTextures (loader: THREE.TextureLoader, texturesSources: string[], onProgress: (event: ProgressEvent<EventTarget>) => void) {
    return texturesSources.map(textureSource => {
        return new Promise((resolve, reject) => {
            loader.load(
                textureSource,
                texture => resolve(texture),
                onProgress, // onProgress callback not supported from r84
                err => reject(err)
            )
        })
    })
}

export function getAssets (loader: THREE.TextureLoader, assets: AssetsStore, onProgress: (event: ProgressEvent<EventTarget>) => void) {
    const flatten = (arr) => arr.reduce((flat, next) => flat.concat(Array.isArray(next) ? flatten(next) : next), []); 
    return Object.entries(assets).map(([assetsKey, section]) => {
        if(assetsKey == 'videos' && !isEmptyObject(section) ) {          
            return Object.entries(section).map(([key, val]) => {
                loader.manager.itemStart( "assets/dubai_night.mp4" ); // notifying about start of loading process

                let onVideoLoad = () => {

                    video.removeEventListener( 'loadedmetadata', onVideoLoad, false );
                    //video.play();
                    const videoTexture = new THREE.VideoTexture( video );
                    assets[assetsKey][key].video = videoTexture;
                    loader.manager.itemEnd( "assets/dubai_night.mp4" ); // notifying about end of loading process               
    
                }
                const video = document.createElementNS( 'http://www.w3.org/1999/xhtml', 'video' ) as HTMLVideoElement;
                //video.addEventListener( 'loadedmetadata',  onVideoLoad, false );
                video.pause();
                video.src = "assets/dubai_night.mp4";
                //video.preload = "auto";
                video.autoplay = true;
                video.muted = true;
                video.loop = true;
    
                //video.setAttribute( 'webkit-playsinline', 'webkit-playsinline' );
                //video.setAttribute( 'playsinline', '' );
                //video.setAttribute( 'autoplay', 'true' );
                //video.setAttribute( 'muted', '' );
                //video.load();   
                
                return new Promise((resolve,reject) => {
                    function onCanPlay() {
                        video.removeEventListener('canplaythrough', onCanPlay, false);
                        video.removeEventListener('load', onCanPlay, false);
                        video.removeEventListener('loadeddata', onCanPlay, false);
                        //video is ready
                        video.play();
                        const videoTexture = new THREE.VideoTexture( video );
                        assets[assetsKey][key].video = videoTexture;
                        loader.manager.itemEnd( "assets/dubai_night.mp4" ); // notifying about end of loading process  
                        console.log('video is loaded')
                        resolve(videoTexture) ;
                    }

                    video.play(); //start loading, didn't used `vid.load()` since it causes problems with the `ended` event

                    if(video.readyState !== 4){ //HAVE_ENOUGH_DATA
                        video.addEventListener('canplaythrough', onCanPlay, false);
                        video.addEventListener('load', onCanPlay, false); //add load event as well to avoid errors, sometimes 'canplaythrough' won't dispatch.
                        video.addEventListener('loadeddata', onCanPlay, false);
                        setTimeout(function(){
                            video.pause(); //block play so it buffers before playing
                        }, 1); //it needs to be after a delay otherwise it doesn't work properly.
                    }else{
                        //video is ready
                    }                    
                }   
              );
            })
        } else {
            return Object.entries(section).map(([key, val]) => {
                return new Promise((resolve, reject) => {
                    loader.load(
                        //`https://picsum.photos/2048/2048?random=${key}`,
                        'assets/white.png',
                        texture => {
                            assets[assetsKey][key].texture = texture;
                            console.log('texture is loaded');
                            resolve(texture)
                        },
                        undefined, // onProgress callback not supported from r84
                        err => reject(err)
                    )
                })
            })
        }
    }).reduce((flat, next) => flat.concat(Array.isArray(next) ? flatten(next) : next), [])
}


export function isMobile() {
    // credit to Timothy Huang for this regex test: 
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return true
    }
    else{
        return false
    }
} 


export const uid = function(){
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export * from './loader';
export * from './PrefabBufferGeometry';