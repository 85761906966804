import { gsap } from 'gsap';
import { lerp, getMousePos, getBoundingRect, mix, calculateDistance } from '../utils';

// Track the mouse position


export class Cursor {
    private DOM: {el: HTMLElement, ring: HTMLElement}
    private bounds: DOMRect
    private renderedStyles: { tx: { previous: number; current: number; amt: number; }; ty: { previous: number; current: number; amt: number; }; scale: { previous: number; current: number; amt: number; }; opacity: { previous: number; current: number; amt: number; }; };
    private mouse: { x: number; y: number; };
    private cursorAppend: boolean;
    private isHovered: boolean;
    private target: HTMLElement;
    private rectangle: DOMRect;
    private tlElement: gsap.core.Timeline
    private tlRing: gsap.core.Timeline

    constructor(el: HTMLElement) {
        this.cursorAppend = false;
        this.isHovered = false;
        this.DOM = {el: el, ring: el.querySelector('.ring')};
        this.DOM.el.style.opacity = '0';
        this.bounds = this.DOM.el.getBoundingClientRect();   
        this.target = this.DOM.el;
        this.rectangle = new DOMRect;
        
        this.tlElement = gsap.timeline();
        this.tlRing = gsap.timeline();
        this.renderedStyles = {
            tx: {previous: 0, current: 0, amt: 0.2},
            ty: {previous: 0, current: 0, amt: 0.2},
            scale: {previous: 1, current: 1, amt: 0.2},
            opacity: {previous: 1, current: 1, amt: 0.2}
        };
        this.mouse = {x: 0, y: 0};
        window.addEventListener('mousemove', this.onMouseMoveEv);
    }
    
    private onMouseMoveEv = () => {
        this.renderedStyles.tx.previous = this.renderedStyles.tx.current = this.mouse.x - this.bounds.width/2;
        this.renderedStyles.ty.previous = this.renderedStyles.ty.previous = this.mouse.y - this.bounds.height/2;
        gsap.to(this.DOM.el, {duration: 0.9, ease: 'Power3.easeIn', opacity: 1}).then(() => this.cursorAppend = true);

        window.removeEventListener('mousemove', this.onMouseMoveEv);
    };
    public enter(target?: HTMLElement) {
        //gsap.killTweensOf(this.DOM.el);
        gsap.killTweensOf(this.DOM.ring);
        this.isHovered = true;
        this.target = target;
        this.renderedStyles['scale'].current = 1.1; 
        this.rectangle = getBoundingRect(this.target);;
        let borderRadius = window.getComputedStyle(this.target).borderRadius;

        // set defaults
        this.tlRing.to(this.DOM.ring, {
            duration: 0, 
            borderColor: "#e2d5c0",
            opacity: 1,
        });
        // animation
        this.tlElement = gsap.timeline().fromTo(this.DOM.el, {
            duration: 0.3, 
            width: "25px",
            height: "25px"
        }, {
            duration: 0.3,     
            width: this.target.clientWidth,
            height: this.target.clientHeight
        });
        this.tlRing.to(this.DOM.ring, {
            duration: 0.3, 
            borderRadius: borderRadius,
            borderColor: "#f9f0b1",
            opacity: 0,
        });
        
    }
    public leave(target?: HTMLElement) {
        //gsap.killTweensOf(this.DOM.el);
        gsap.killTweensOf(this.DOM.ring);
        this.isHovered = false;
        this.renderedStyles['scale'].current = 1;
        this.tlElement.reverse();
        this.tlRing.to(this.DOM.ring, {
            duration: 0.4, 
            borderRadius: "50%",
            borderColor: "#e2d5c0",
            opacity: 1,
        });
    }
    public update(ev: MouseEvent) {
        this.mouse = getMousePos(ev);
        this.render();

    }
    private render() {
        let progress = this.tlElement.progress();
        
        this.renderedStyles['tx'].current = mix(this.mouse.x - this.bounds.width/2, this.rectangle.left +
            (this.mouse.x - this.rectangle.left -  this.target.clientWidth / 2) / 10, progress);
        this.renderedStyles['ty'].current = mix(this.mouse.y - this.bounds.height/2, this.rectangle.top +
            (this.mouse.y - this.rectangle.top -  this.target.clientHeight / 2) / 10, progress);

        for (const key in this.renderedStyles ) {
            this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
        }
                    
        this.DOM.el.style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px) scale(${this.renderedStyles['scale'].previous})`;
        if (this.cursorAppend) {
         this.DOM.el.style.opacity = this.renderedStyles['opacity'].previous.toString();
        }

        //requestAnimationFrame(() => this.render());
    }
}