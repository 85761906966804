export class Loader {
    items: any[];
    loaded: any[];
  
    constructor() {
      this.items = [];
      this.loaded = [];
    }
    begin(name) {
      this.items.push(name);
    }
    end(name) {
      console.log("loaded:" + name);
      this.loaded.push(name);
      if (this.loaded.length === this.items.length) {
        this.onComplete();
      }
    }
    onComplete() {}
  }
  