import { gsap } from "gsap";
import { HTMLExt, HTMLExtBase, isMobile, willChange } from "Utils";

export class HorizontalScroll {
    private scrollTrigger: gsap.plugins.ScrollTrigger;
    private horizontSections: (HTMLElement & HTMLExt)[];
    private locoScroll: any;

    constructor(scrollTrigger: gsap.plugins.ScrollTrigger, scroll: any) {
        this.scrollTrigger = scrollTrigger;
        this.locoScroll = scroll;
    }

    public init() {
        this.horizontSections = gsap.utils.toArray<HTMLElement & HTMLExt>("section.horizontal");

        this.horizontSections.forEach((item, i) => {
            item._sh = item.querySelector<HTMLElement>(".space-holder");
            item._title = item.querySelector<HTMLElement>(".catchy");
            //item._pw = item.querySelector<HTMLElement>(".pin-wrapper");
            item._aw = item.querySelector<HTMLElement>(".animation-wrapper");

            if ( item._aw === null) {
                return;
            }

            var items = item._aw.querySelectorAll<HTMLElement>('.item');
            let scrollWidth: number = 0;  
            items.forEach(x => scrollWidth += x.offsetWidth);

            let horizontalScrollLength = scrollWidth - item.offsetWidth;

                        //let titleHeight = item._title.clientHeight;
            //(item as HTMLElement).style.height = (animWrapperWidth + window.innerHeight + titleHeight ) + 'px';
            //(item._sh as HTMLElement).style.height = (scrollWidth + window.innerHeight) + 'px';
            
            //(item._aw as HTMLElement).style.width = scrollWidth + 'px';

            let animWrapperWidth = item._aw.clientWidth;
            
            if (item._aw.classList.contains('to-right')) {
                var fromValue = 0;
                var toValue = -(scrollWidth - scrollWidth / items.length) + item.offsetWidth; 
                // 1600 Total Padding left & right of all items
            
            }      

            if (item._aw.classList.contains('to-left')) {
                //var fromValue = -(window.innerWidth * 0.5 - 80) ; 
                //var fromValue = -(scrollWidth / items.length - 80 * items.length);
                //var fromValue = -scrollWidth / items.length + 80 * items.length;
                var fromValue = 0;
                // 1600 Total Padding left & right of all items
                //var toValue = scrollWidth - (scrollWidth / items.length + window.innerWidth * 0.5 - 80);
                //var toValue = scrollWidth / items.length + 80 * items.length;
                var toValue = scrollWidth - scrollWidth / items.length;
            }

            //var horizontalSectionTween = gsap.fromTo(item._aw, { x: fromValue }, { x: toValue, ease: "none" });
            //let ySetter = gsap.quickSetter((item._aw as HTMLElement), "y", "px");
            //let scroller = document.querySelector<HTMLElement>('[data-scroll-section]');
            //let scroller = document.querySelector<HTMLElement>('.smooth-scroll');
            let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || 
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
            
            let horizontalTl = gsap.timeline();

            horizontalTl.to(item._aw as HTMLElement, {
                scrollTrigger: {
                    id: "horizontalSectionScrolling",
                    scroller: ".smooth-scroll",
                    scrub: true,
                    trigger: item._sh,
                    pin: true,
                    //pin: "#animate-"+i,
                    // anticipatePin: 1,
                    start: "top top",
                    end: "+="+scrollWidth,
                    //markers: true,
                    //pinType: "fixed",
                    onRefresh: self => {
                        console.log(self);
                        //((self as any).spacer as HTMLElement).style.height = '2000px';    
                        //self.vars.trigger =  item._sh as Element;
                        this.locoScroll.update();
                    },
                    onEnter: () => {
                        //willChange(item._aw as HTMLElement, true);
                    },
                    onEnterBack: () => {
                        //willChange(item._aw as HTMLElement, true);
                    },
                    onLeave: ({progress, direction, isActive}) => {
                        //(item._aw as HTMLElement).style.top = "";	
                        //willChange(item._aw as HTMLElement, false);  
                    },
                    onLeaveBack: ({progress, direction, isActive}) => {
                        //(item._aw as HTMLElement).style.top = "";	
                        //willChange(item._aw as HTMLElement, false);  					
                    }
                  },
                  x: -horizontalScrollLength,
                  ease: "none"                  
            });             	
        });           
        
        this.locoScroll.update();
    }
}