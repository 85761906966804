import { gsap } from "gsap";
import { clamp, HTMLExt, HTMLExtData, isEmpty, isEmptyObject, PageData, willChange } from "Utils";

export default class AnimateScroll {
  private lastIndex: number;
  private sharedData: PageData[];
  private scrollTrigger: gsap.plugins.ScrollTrigger
  private locoScroll: any;
  private navDots: (HTMLElement & HTMLExtData)[];
  private dots: HTMLElement;
  private toolTips: NodeListOf<HTMLElement>;
  private toolTipsParent: HTMLElement;

  constructor(scrollTrigger: gsap.plugins.ScrollTrigger, scroll: any) {
      this.scrollTrigger = scrollTrigger;
      this.locoScroll = scroll;
      this.lastIndex = -1;
      this.sharedData = [];
      this.navDots = [];
      this.dots = document.createElement('div') as HTMLElement;
      this.toolTips = document.querySelectorAll<HTMLElement>(".toolTip");
      this.toolTipsParent = document.querySelector<HTMLElement>(".toolTips");
    }

  public init(data: PageData[]) {
    this.sharedData = data;
    //this.lastIndex = this.sections.length - 1;
    this.lastIndex = this.sharedData.length - 1;
    this.dots = document.querySelector<HTMLElement>(".dots");
    this.locoScroll.on('call', (args, direction, obj) => {
      if (args === 'update') {
        this.updateSection(direction, obj);
      }
    });

    this.sharedData.forEach((item, i) => {
      var isHorizontal = item.section.classList.contains('horizontal');

      item.section._sb = item.section.querySelector<HTMLElement>(".section-background");
      if (item.section.getElementsByClassName('to-left').length) {
        var nodes = item.section.querySelectorAll<HTMLElement>("h1");
        item.section._h1 = nodes[nodes.length - 1];
        this.hide(item.section._h1);
        
      } else {
        item.section._h1 = item.section.querySelector<HTMLElement>("h1");
        this.hide(item.section._h1);
      }
      item.section._canvas = item.section.querySelector<HTMLElement>("canvas");
      item.section._center = item.section.querySelector<HTMLElement>(".center-anchor");
      item.section._tl = gsap.timeline();
      item.section._tl2 = gsap.timeline();
      item.section._tlDot = gsap.timeline({paused:true, defaults:{ease:'none', overwrite:'auto'}});
      
      this.prepareWebglBackground(item, i, isHorizontal);

      //var isCatchy = item.section.classList.contains('catchy');
      let catchyElement = item.section.classList.contains('catchy') ? (item.section as HTMLElement) :  item.section.querySelector<HTMLElement>('.catchy');
      if (catchyElement !== null) {
        item.section._catchy = catchyElement;
        this.prepareParallax(item, i);
      }
        
      // Create trigger for set ' overflow: visible; '
      if(i !== this.lastIndex) {
        this.scrollTrigger.create({
          trigger: item.section._h1,
          scroller: ".smooth-scroll",
          //toggleClass: { targets: item.section, className: 'allow-overflow' },
          markers: false,
          id: `overflow-${i}`,
          onEnter: () => { 
            willChange(item.section._h1 as HTMLElement, true);
            this.animateFrom(item.section._h1) 
          }, 
          onEnterBack: () => { 
            willChange(item.section._h1 as HTMLElement, true);
            this.animateFrom(item.section._h1, -1) 
          },
          onLeave: () => { 
            willChange(item.section._h1 as HTMLElement, false);
            this.hide(item.section._h1) 
          },
          onLeaveBack: () => { 
            willChange(item.section._h1 as HTMLElement, false);
            this.hide(item.section._h1) 
          }
          //start: ()=> i == 0 ? "top-=50% top" : "top-=50% bottom",
          //end: ()=> "bottom+=50% top"
        });
      }
      
      // Create navigation dots
      this.prepareNavDots(item, i);
      this.toolTipsParent.style.display = 'none';
    });


    // Create scrollTo behaviour
    document.querySelectorAll<HTMLElement>('footer .page-scroll').forEach(item => {
      let targetId = item.dataset.section;
      item.addEventListener("click", () => {
        this.locoScroll.scrollTo(targetId);
      });
    }); 
  }

  prepareWebglBackground(item: PageData, i: number, isHorizontal: boolean): void {
    if (isEmptyObject(item)) {
      return;
    }
      // Set size of dead zone to background animation of horizontal section
      let sizeRatio = 0;
      if (isHorizontal) {
        var items = (item.section._aw as HTMLElement).querySelectorAll<HTMLElement>('.item');
        let scrollWidth: number = 0;  
        items.forEach(x => scrollWidth += x.clientWidth);
        //sizeRatio = ((item.section._aw as HTMLElement).clientWidth - window.innerWidth + window.innerHeight) / window.innerHeight;
        sizeRatio = ((item.section._aw as HTMLElement).clientWidth - window.innerWidth + window.innerHeight) / window.innerHeight;
      }
      else {
        sizeRatio = 1;
      }
      // Give the backgrounds some random images
      //item.section._sb.style.backgroundImage = `url(https://picsum.photos/${innerWidth}/${innerHeight*2}?random=${i})`;
    
      // Create a standalone ST instance, and use the progress value (0 - 1) to tween the timeline's progress
      if (isEmptyObject(item.section)) {
        return;
      }
      this.scrollTrigger.create({ 
        trigger: item.section,
        scroller: ".smooth-scroll",
        start: ()=> i == 0 ? "top top" : "top bottom", // The FIRST section will use a different start value than the rest
        //end: ()=> i == this.lastIndex ? "top top" : "bottom top", // The LAST section will use a different start value than the rest    
        end: ()=> i == this.lastIndex ? "top top" : "bottom top",
        onRefresh: self => { 
          // onRefresh (so it gets reset upon resize), create a timeline that moves the h1 + sb vertically     
          let progress = this.getProgress(isHorizontal, self, sizeRatio); 
          if (!isEmptyObject(item.section._center)) {
            item.section._tl = gsap.timeline({paused:true, defaults:{ease:'none', overwrite:'auto'}})                
            .fromTo(item.section._center, {y:()=> i==0 ? 0 : (innerHeight/2)*.5}, {y:()=> i==0 ? -innerHeight/2 : i == this.lastIndex ? 0 : (-innerHeight/2)*.5}, 0)
            //.fromTo(item.section._sb, {y:()=> i==0 ? -innerHeight/2 : 0}, {y:()=> i == this.lastIndex ? -innerHeight/2 : -innerHeight}, 0)	  
            //.fromTo(item.section._sb, {y:()=> i==0 ? -innerHeight/2 : -innerHeight/2}, {y:()=> i == this.lastIndex ? -innerHeight/2 : (innerHeight/2)}, 0)
            .progress(progress); //use progress to position the timeline correctly 
          }
          // parallax webgl background
          //if (!isEmptyObject(item.section._sb)) {
          //  item.section._tl2 = gsap.timeline({paused:true, defaults:{ease:'none', overwrite:'auto'}}) 
          //  .fromTo(item.section._sb, {y:()=> i==0 ? 0 : -innerHeight / 2 }, {y:()=> i==0 ? (innerHeight / 3) : i == this.lastIndex ? 0: (innerHeight / 2) }, 0)
          //  .progress(progress); //use progress to position the timeline correctly 
          //}
          // Navigation dot progress
          (item.section._tlDot as gsap.core.Timeline).progress(i==0 ? Math.cos(Math.PI * progress * 0.5) : i == this.lastIndex ? Math.sin(Math.PI * progress * 0.5) : Math.sin(Math.PI * progress));
        },
        markers: true,
        onUpdate: self => { 
            let progress = this.getProgress(isHorizontal, self, sizeRatio);
            if (!isEmptyObject(item.section._center)) {
              gsap.to(item.section._tl, {duration:0.75, progress: i==0 ? progress : i == this.lastIndex ? 0 : progress});
            }  
            (item.section._tl2 as gsap.core.Timeline).progress(progress); 
            (item.section._tlDot as gsap.core.Timeline).progress(i==0 ? Math.cos(Math.PI * progress * 0.5) : i == this.lastIndex ? Math.sin(Math.PI * progress * 0.5) : Math.sin(Math.PI * progress));
          },
        onLeave: self => {
          // gsap.to(item.section._tl, {duration:0.5, paused: false, progress:self.progress}); 
          // (item.section._tl as gsap.core.Timeline).progress(1).play();
        }
      });
      this.scrollTrigger.refresh();
  }

  prepareParallax(item: PageData, i: number) {
    item.section._bg = (item.section._catchy as HTMLElement).querySelector<HTMLElement>(".bg"); 
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    // Give the backgrounds some random images
    //item.section._bg.style.backgroundImage = `url(https://picsum.photos/${innerWidth}/${innerHeight}?random=${i})`;
    item.section._bg.style.backgroundImage = `url(/assets/logo-gray-pattern.jpg)`;

    // Do the parallax effect on each section
    if (i) {
      item.section._bg.style.backgroundPosition = `50% 0%`;

      gsap.to(item.section._bg, {
        backgroundPosition: `50% 100%`,
        ease: "none",
        scrollTrigger: {
          trigger: item.section._catchy as HTMLElement,
          scroller: ".smooth-scroll",
          scrub: true,
          onEnter: () => {
            willChange(item.section._bg as HTMLElement, true, 'transform, background');
          },
          onLeave: () => {
            willChange(item.section._bg as HTMLElement, false, 'none');
          }
        }
      });
    } 
    
    // the first image should be positioned against the top. Use px on the animating part to work with GSAP. 
    else {
      item.section._bg.style.backgroundPosition = "50% 0px"; 

      gsap.to(item.section._bg, {
        backgroundPosition: `50% ${innerHeight / 2}px`,
        ease: "none",
        scrollTrigger: {
          trigger: item.section._catchy as HTMLElement,
          scroller: ".smooth-scroll",
          start: "top top", 
          end: "bottom top",
          scrub: true,
          onEnter: () => {
            willChange(item.section._bg as HTMLElement, true, 'transform, background');
          },
          onLeave: () => {
            willChange(item.section._bg as HTMLElement, false, 'none');
          }
        }
      });
    }
  }

  prepareNavDots(item: PageData, i: number) { 
    let newDot = document.createElement("div") as HTMLElement as (HTMLElement & HTMLExtData);
    newDot.tlTooltip = gsap.timeline({paused:true, reversed:true});
    newDot.tlTooltip.to(this.toolTips[i], {duration: 0.25, opacity:1, ease: "none"});
    //newDot.tlDot = gsap.timeline({paused:true});
    (item.section._tlDot as gsap.core.Timeline).to(newDot, {duration: 0.5, scale: 1.6, rotation: 0.1, ease: "none" });
    //newDot.tlDot.progress = (item.section._tl2 as gsap.core.Timeline).progress;
    newDot.className = "dot";  
    newDot.index = i;
    newDot.setAttribute('data-jump-to', item.section.id);
    this.navDots.push(newDot);
    newDot.addEventListener("click", (event) => this.targetScroll(event));
    newDot.addEventListener("mouseenter", (event) => { 
      this.toolTipsParent.style.display = '';
      this.dotHover(event)
    });
    newDot.addEventListener("mouseleave", (event) => {
      this.toolTipsParent.style.display = 'none';
      this.dotHover(event)
    });
    this.dots.appendChild(newDot);
    //offsets.push(-slides[i].offsetTop);
    //toolTipAnims.push(tl);
  }

  targetScroll(e: MouseEvent) {
    // click on a dot
    if ((e.target as (HTMLElement & HTMLExtData)).classList.contains('dot')) {
      let index = (e.target as (HTMLElement & HTMLExtData)).index as number;
      this.locoScroll.scrollTo(this.sharedData[index].section);
    } 
  }  

  // tooltips hovers
  dotHover(e: MouseEvent) {
    if ((e.target as (HTMLElement & HTMLExtData)).classList.contains('dot')) {
      let timeLine = ((e.target as (HTMLElement & HTMLExtData)).tlTooltip as gsap.core.Timeline);
      timeLine.reversed() ? timeLine.play() : timeLine.reverse();
    }
  }

  updateSection(direction: string, el: HTMLElement) {
    console.log(direction);
  }

  animateFrom(elem, direction?) {
      direction = direction | 1;
      
      var x = 0,
          y = direction * 500;
      if(elem.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = 0;
      } else if(elem.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 0;
      }
      gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 1.25, 
        x: 0,
        y: 0, 
        autoAlpha: 1, 
        ease: "expo", 
        overwrite: "auto"
      });
    }
    
    hide(elem) {
      gsap.set(elem, {autoAlpha: 0});
    }
  
  private getProgress(isHorizontal: boolean, scrollTrigger: gsap.plugins.ScrollTriggerInstance, sizeRatio: number) {
    if (!isHorizontal) {
      return scrollTrigger.progress;
    }
    else {
      var x = scrollTrigger.progress;
      var normalized = clamp(Math.ceil(x) * x * sizeRatio, 0.0, 0.5) + clamp(x * sizeRatio - (sizeRatio - .5), 0.0, 0.5);
      return normalized;
    }
  }
}