import * as THREE from "three";

import { AssetsStore, drawBoundsForElement, IFunction, PageData } from "Utils";
import CrumblyMesh from "./CrumblyMesh";

export default class BackgroundView {
    private assets: AssetsStore;
    private sharedData: PageData[];
    private sceneElements: {elem: HTMLElement, ctx: CanvasRenderingContext2D, mesh: CrumblyMesh, fn: (time: number, rect: any) => void}[]
    private renderer: THREE.WebGLRenderer;
    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    private clearColor: THREE.Color;
    private sceneInitFunctionsByName: IFunction;

    constructor(canvasElem: HTMLCanvasElement, assets?: AssetsStore) {
        this.assets = assets;
        this.sharedData = [];
        this.sceneElements = [];
        this.renderer = new THREE.WebGL1Renderer({
            canvas: canvasElem,
            //antialias: true,
            alpha: true
        });
        //this.renderer.autoClear = false;
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.renderer.setViewport(0, 0, window.innerWidth, window.innerHeight );   
        this.camera = new THREE.PerspectiveCamera(45, 1, 0.1, 100);
        this.camera.position.z = 15;
        this.scene = new THREE.Scene();      
        this.clearColor = new THREE.Color('#000');
        this.scene.background = null;

        const sceneInitFunctionsByName = {
            'first': (elem: HTMLElement, mesh: CrumblyMesh, sceneName: string) => {  
                let section = this.sharedData.find(x => x.elem === elem)?.section;
                mesh.init(this.scene, this.camera, section, sceneName);
                return (time: number, rect: DOMRect): void => {
                    mesh.update(time, rect.bottom);
                };
            },
            'second': (elem: HTMLElement, mesh: CrumblyMesh, sceneName: string) => {  
                let section = this.sharedData.find(x => x.elem === elem)?.section;
                mesh.init(this.scene, this.camera, section, sceneName);
                return (time: number, rect: DOMRect): void => {
                    mesh.update(time, rect.bottom);
                };
            }
        };

        this.sceneInitFunctionsByName = { ...sceneInitFunctionsByName};

    }

    public init(data: PageData[]): void {
        this.sharedData = data;
        const {scene, camera} = this.makeGridScene();
        this.scene = scene;
        this.camera = camera;

        document.querySelectorAll<HTMLElement>('[data-fxview]').forEach((elem) => {
            var sceneName = elem.dataset.fxview;
            const mesh = new CrumblyMesh(this.assets);
            var sceneInitFunction = this.sceneInitFunctionsByName[sceneName];
            var sceneRenderFunction = sceneInitFunction(elem, mesh, sceneName);
            this.addScene(elem, mesh, sceneRenderFunction);
        });
    }

    private addScene(elem: HTMLElement, mesh: CrumblyMesh, fn: (time: number, rect: any) => void): void {    
        this.sceneElements.push({elem, mesh, ctx: null, fn});
    }

    public update(secs: number): void {       
        this.render(secs);
    }

    private makeGridScene(): {scene: THREE.Scene, camera: THREE.PerspectiveCamera} {
        const scene = new THREE.Scene();

        const fov = 45;
        //onst aspect = 2;  // the canvas default
        let aspect = window.innerWidth / window.innerHeight;
        const near = 0.1;
        const far = 10000;
        const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
        camera.position.set(0, 0, 2);
        camera.lookAt(0, 0, 0);
        let dist = camera.position.z;
        camera.position.y = -window.innerHeight / 2;
        let height = window.innerHeight;
        let width = window.innerWidth;
        camera.fov = 2 * Math.atan((width / 2  ) / (aspect * dist)) * (180 / Math.PI);
        camera.updateProjectionMatrix();
        scene.add(camera);
        
        return {scene, camera};
    }

    private render(time) {
        
        var transform = `translateY(${window.scrollY}px)`;
        //this.renderer.domElement.style.transform = transform;

        var visibleAny = false;
   
        for (var {elem, fn, mesh} of this.sceneElements) {
          // get the viewport relative position of this element
          var rect = elem.getBoundingClientRect();          
          var {left, right, top, bottom, width, height} = rect;
    
          var isOffscreen =
              bottom < 0 ||
              top > this.renderer.domElement.clientHeight ||
              elem.parentElement.style.opacity === '0'
              //right < 0 ||
              //left > this.renderer.domElement.clientWidth;      
              
              if (!isOffscreen) {   
                mesh.backgroundMesh.visible = true;                    
                fn(time, rect);
              } 
              else {
                mesh.backgroundMesh.visible = false;
              }
            
            visibleAny = visibleAny || !isOffscreen;     

        }
        //this.scene.children[2].visible = false;
        //this.scene.children[3].visible = false;
        //this.scene.children[4].visible = false;
        if (visibleAny) {
            this.renderer.render(this.scene, this.camera);
        }
    }
}