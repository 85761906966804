/*
 * InteractiveHeader.ts
 * ===========
 * Hidding header inspired by https://medium.com/@mariusc23/hide-header-on-scroll-down-show-on-scroll-up-67bbaae9a78c
 */
import { gsap } from "gsap";
import { HTMLExt, outerHeight } from '../utils';

export class InteractiveHeader {
    private didScroll: boolean;
    private lastScrollTop: number;
    private headreEl: HTMLElement;
    private navEl: HTMLElement;
    private navItems: NodeListOf<Element>;
    private delta: number;
    private headerHeight: number;
    private locoScroll: any;
    public isMenuActive: boolean;
    private hamburgerLines: (HTMLElement & HTMLExt)[];


    constructor(scroll: any) {
        this.locoScroll = scroll;
        this.didScroll = false;
        this.lastScrollTop = 0;
        this.headreEl = document.getElementById('main-header');
        this.navEl = document.querySelector('.nav-wrapper');
        this.navItems = document.querySelectorAll('.nav-item');
        this.headerHeight = outerHeight(this.headreEl);
        this.locoScroll.on("scroll", () => this.didScroll = true);
        this.isMenuActive = false;
          
        this.init();
        this.setNavMenuClickHandler();

        setInterval(() => {
            if (this.didScroll) {
                this.hasScrolled();
                this.didScroll = false;
            }
        }, 250);
    }

    private hasScrolled() {
        var st = this.locoScroll.scroll.instance.scroll.y;
        // Make sure they scroll more than delta
        if(Math.abs(this.lastScrollTop - st) <= this.delta)
            return;
        
        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        var hr = document.getElementById('header-wrapper');
        if (st > this.lastScrollTop && st > this.headerHeight){
            // Scroll Down

            hr.classList.remove('nav-down');
            hr.classList.add('nav-up');
        } else {
            // Scroll Up
            if(st + window.innerHeight < document.body.scrollHeight) {
                hr.classList.remove('nav-up');
                hr.classList.add('nav-down');
            }
        }
        
        this.lastScrollTop = st;
    }

    public init() {
        this.hamburgerLines = gsap.utils.toArray<HTMLElement & HTMLExt>(".burger-icon .line");
    }

    private setNavMenuClickHandler() {
        let navToggle = document.querySelector(".nav-toggle");
        let navWrapper = document.querySelector(".nav-wrapper");
        let dots = document.querySelector(".dots");

        navToggle?.addEventListener("click", () => {
            if (navWrapper?.classList.contains("active")) {
                navToggle.setAttribute("aria-expanded", "false");
                navToggle.setAttribute("aria-label", "menu");
              navWrapper?.classList.remove("active");
              dots?.classList.remove("active");
            } else {
              navWrapper?.classList.add("active");
              dots?.classList.add("active");
              navToggle.setAttribute("aria-label", "close menu");
              navToggle.setAttribute("aria-expanded", "true");
            } 
            this.toggleMenu();
          });
    }

    private toggleMenu() {
        if(Array.isArray(this.hamburgerLines) && this.hamburgerLines.length) {
            if (!this.isMenuActive) {
                gsap.to(this.hamburgerLines[0], {duration: .3, rotation: 45, scaleX: 1.25, y: 19.5, borderColor: '#8292ab', backgroundColor: '#8292ab', delay: .2});
                gsap.to(this.hamburgerLines[1], {duration: .3, opacity: 0, y: 50, scaleX: 0.1, borderColor: '#8292ab', backgroundColor: '#8292ab', delay: .2})
                gsap.to(this.hamburgerLines[2], {duration: .3, rotation: -45, scaleX: 1.25, y: -19.5, borderColor: '#8292ab', backgroundColor: '#8292ab', delay: .2})
            } else {
                gsap.to(this.hamburgerLines[0], {duration: .3, rotation: 0, scaleX: 1, y: 0, borderColor: '#bbb', backgroundColor: '#f1e7c9', delay: .2})
                gsap.to(this.hamburgerLines[1], {duration: .3, opacity: 1, y: 0, scaleX: 1, borderColor: '#bbb', backgroundColor: '#f1e7c9', delay: .2})
                gsap.to(this.hamburgerLines[2], {duration: .3, rotation: 0, scaleX: 1, y: 0, borderColor: '#bbb', backgroundColor: '#f1e7c9', delay: .2})                
            }
            this.playMenuBackgrounds(this.isMenuActive);
            this.isMenuActive = !this.isMenuActive; 

        } 
    }

    private playMenuBackgrounds(active: boolean)  {
        if (active === false) {
            this.locoScroll.stop();
            
            //gsap.to(nav, 0, {scaleY: 1})
            //gsap.fromTo(navBackgroundBlack, .6,  {y: "-2500px"}, {y: 0, delay: .17, display: 'block', ease: Power3.easeOut})
            gsap.fromTo(this.navEl, {duration: .8, y: "-100%"}, {y: "0%", delay: .2, ease: 'easeOut'})
            gsap.fromTo(this.navItems,
                {y: -60, opacity: 0},
                {y: 0, opacity: 1, delay: .3,
                stagger: {
                each: .2
                }
            })
            //gsap.fromTo(info, .4, {opacity: 0, x: 50}, {opacity: 1, x: 0, delay: 1.8})
        }
        else if (active === true)
        {
            this.locoScroll.start();

            //gsap.to(navBackgroundBlack, .4, {y: "-2500px", delay: .2, ease: Power3.easeIn})
            gsap.to(this.navEl, {duration: .4, y: "-100%", delay: .17, ease: 'easeIn'})
            //gsap.to(nav, 0, {scaleY: 0, delay: .6})         
        }
      }
}