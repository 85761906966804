import * as THREE from "three";

import vertexShader from "./glsl/gridscroll.vs";
import fragmentShader from "./glsl/grid.fs";
import { Uniforms, HTMLExt, shuffleData, cover, makeUVs, coverTransformation, AssetsStore, PrefabBufferGeometry } from "Utils";


import * as BAS from "three-bas";
import { BufferGeometry } from "three";
import { isEmptyObject } from "jquery";

export default class CrumblyMesh {
    // grid settings
    private assets: AssetsStore;
    private gridHelper: THREE.GridHelper;
    private material: THREE.RawShaderMaterial;
    public backgroundMesh: THREE.Mesh;
    private index: number;
    // init return data
    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    // grid settings
    private sizeX: number;
    private sizeY: number;
    private numDivisions: number;
    private transformMatrix: THREE.Matrix3;
    // uniforms
    private uTime: THREE.IUniform;
    private uTexture: THREE.IUniform;
    private section: HTMLElement & HTMLExt;
    private uProgress: THREE.IUniform;
    private uChangedPosition: THREE.IUniform;


    constructor(assets?: AssetsStore) {      
        // set geometry defaults 
        this.assets = assets;

        // set default value uniforms
        this.uTime = new THREE.Uniform({ value: 0 });
        this.uTexture = new THREE.Uniform({ value: new THREE.Texture() })
        this.uProgress = new THREE.Uniform({ value: 0 });
        this.uChangedPosition = new THREE.Uniform({ value: 0 });

        this.index = 0;
        // set settings
        //this.size = 960;
        this.sizeX = window.innerWidth;
        this.sizeY = window.innerHeight;
        this.numDivisions = 200;
    }

    public init(scene: THREE.Scene, camera: THREE.PerspectiveCamera, section: HTMLElement & HTMLExt, sectionName?: string, textureUrl?: string): void {
        this.scene = scene;
        this.camera = camera;
        this.section = section;
        this.index = Array.prototype.indexOf.call(this.section.parentElement.children, this.section);
        var arr = new Array();

        if (textureUrl === undefined) {
          //this.createDefaultTexture();
        } else {
          //this.createTexture(textureUrl);
        }
        if (!isEmptyObject(this.assets.textures[sectionName])) {
          this.uTexture.value = this.assets.textures[sectionName].texture;
        }
        else if(!isEmptyObject(this.assets.videos[sectionName])) {
          this.uTexture.value = this.assets.videos[sectionName].video;
        }


        const uniforms: Uniforms = {
            time: this.uTime,
            uTexture: this.uTexture,
            //uTexture: this.assets.textures[sectionName].texture,
            progress: this.uProgress,
            changed: this.uChangedPosition,
            index:  {
                value: this.index
              },
            uRes: {
              value: new THREE.Vector2(window.innerWidth, window.innerHeight)
            },
            PR: {
              value: Number(window.devicePixelRatio.toFixed(1))
            }
        };

        const params = {
            uniforms: uniforms, 
            tweens: new Array()
        }
        //this.transformMatrix = coverTransformation(this.uTexture?.value, window.innerWidth / Math.round(window.innerHeight * 1.5));
        this.transformMatrix = coverTransformation(this.uTexture?.value, window.innerWidth / window.innerHeight);
        this.createGrid({...params}, this.scene)

        //this.setCamera(this.camera)

    }

    private createGrid(params: {uniforms: Uniforms, tweens: any[]}, scene: THREE.Scene) {
      this.sizeX = this.sizeX || 10;
      this.sizeY = this.sizeY || 10;
      this.numDivisions = this.numDivisions || 10;
  
      const center = this.numDivisions / 2;
      const stepX = this.sizeX / this.numDivisions;
      const halfSizeX = this.sizeX / 2;
      const halfSizeY = this.sizeY / 2;

      var singlePlane = new THREE.PlaneBufferGeometry(
        this.sizeX / this.numDivisions,
        this.sizeY / this.numDivisions,
        1,
        1
      ) as THREE.BufferGeometry;

      let geometry = new PrefabBufferGeometry(singlePlane, this.numDivisions * this.numDivisions);        
      
      var startPositionBuffer = geometry.createAttribute('startPosition', 3);
      var endPositionBuffer = geometry.createAttribute('endPosition', 3);
      var controlCornerABuffer = geometry.createAttribute('controlCornerA', 3);
      var controlCornerBBuffer = geometry.createAttribute('controlCornerB', 3);
      // create a buffer for start time per prefab, with the item size of 1
      var rotationBuffer = geometry.createAttribute('rotation', 4);
      var delayBuffer = geometry.createAttribute('delay', 1);

      var planeIndex = 0;
      var temp = [];
      var tempv = new THREE.Vector3();
      var delaysTemp: {index: number, delay: number}[] = []

      for (var y = 0; y < this.numDivisions; y++) {
        for (var x = 0; x < this.numDivisions; x++) {
          // calculate start position
          temp[0] = THREE.MathUtils.mapLinear(x, 0, this.numDivisions - 1, halfSizeX - (this.sizeX / this.numDivisions) * 0.5, -halfSizeX + (this.sizeX / this.numDivisions) * 0.5);
          temp[1] = THREE.MathUtils.mapLinear(y, 0, this.numDivisions - 1, halfSizeY - (this.sizeY / this.numDivisions) * 0.5, -halfSizeY + (this.sizeY / this.numDivisions) * 0.5);
          temp[2] = 0;
          geometry.setPrefabData(startPositionBuffer, planeIndex, temp);       
          // calculate end position
          temp[0] = THREE.MathUtils.mapLinear(x, 0, this.numDivisions - 1, halfSizeX - (this.sizeX / this.numDivisions) * 0.5, -halfSizeX + (this.sizeX / this.numDivisions) * 0.5);
          temp[1] = THREE.MathUtils.mapLinear(y, 0, this.numDivisions - 1, halfSizeY * 2  - (this.sizeY / this.numDivisions) * 0.5, (this.sizeY / this.numDivisions) * 0.5);
          temp[2] = 0;
          geometry.setPrefabData(endPositionBuffer, planeIndex, temp); 
          // calculate control corner A
          temp[0] = THREE.MathUtils.mapLinear(x, 0, this.numDivisions - 1, halfSizeX - (this.sizeX / this.numDivisions) * 0.5, -halfSizeX + (this.sizeX / this.numDivisions) * 0.5);
          temp[1] = THREE.MathUtils.mapLinear(y, 0, this.numDivisions - 1, halfSizeY * 1.1 - (this.sizeY / this.numDivisions) * 0.5, -halfSizeY * 0.9 + (this.sizeY / this.numDivisions) * 0.5);
          temp[0] *= THREE.MathUtils.randFloat(0.1, 0.3) * 5;
          temp[1] *= THREE.MathUtils.randFloat(0.1, 0.3) * 6;
          temp[2] = THREE.MathUtils.randFloat(0, 2);
          geometry.setPrefabData(controlCornerABuffer, planeIndex, temp); 
          // calculate control corner B
          temp[0] = THREE.MathUtils.mapLinear(x, 0, this.numDivisions - 1, halfSizeX - (this.sizeX / this.numDivisions) * 0.5, -halfSizeX + (this.sizeX / this.numDivisions) * 0.5);
          temp[1] = THREE.MathUtils.mapLinear(y, 0, this.numDivisions - 1, halfSizeY * 1.5 - (this.sizeY / this.numDivisions) * 0.5,  -halfSizeY * 0.2 + (this.sizeY / this.numDivisions) * 0.5);
          temp[0] *= THREE.MathUtils.randFloat(0.3, 0.6) * 3;
          temp[1] *= THREE.MathUtils.randFloat(0.3, 0.9) * -6;
          temp[2] = THREE.MathUtils.randFloat(1, 4);
          geometry.setPrefabData(controlCornerBBuffer, planeIndex, temp); 

          // get a random axis
          BAS.Utils.randomAxis(tempv);
          // store the x, y and z values in the array
          tempv.toArray(temp);
          // set the rotation to 2 PI (can be anything)
          temp[3] = Math.PI * 4;
          
          geometry.setPrefabData(rotationBuffer, planeIndex, temp);

          // calculate delay (by index)
          var delay = 1.0 - (planeIndex / (this.numDivisions * this.numDivisions));          
          delaysTemp.push({index: planeIndex, delay: delay});

          planeIndex++;
        }
      }

      // do delays shuffle by rows
      if(delaysTemp.length > 0) {
        for (var i = 0; i < delaysTemp.length; i += this.numDivisions) {
          temp = shuffleData(delaysTemp.slice(i, i + this.numDivisions), this.numDivisions);
          temp.forEach(item => {
            geometry.setPrefabData(delayBuffer, item.index, [item.delay]);
          });
        }
        delaysTemp = [];
        temp = [];
      }


      //const geometry = new THREE.BufferGeometry();
      //geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
     
      const material = new THREE.RawShaderMaterial({
        uniforms: {
          ...params.uniforms, 
          divisions: { value: this.numDivisions },
          tOffsetX: { value: -1.0 },
          tween: { value: new THREE.Vector4(0, 0, 1, 1) },
        },
        vertexShader,
        fragmentShader,
        transparent: true,
        side: THREE.DoubleSide
      })
      material.depthTest = false;

      // test for new uv fitting
      ((geometry as any) as THREE.BufferGeometry).computeBoundingBox();
      let centerPos = new THREE.Vector3(0., 0.);
      let bbox = ((geometry as any) as THREE.BufferGeometry).boundingBox;
      let bboxSize = ((geometry as any) as THREE.BufferGeometry).boundingBox.getSize(centerPos);

      var sizeVector = new THREE.Vector3(this.sizeX, this.sizeY, 0);

      var offset = new THREE.Vector2(THREE.MathUtils.mapLinear(this.numDivisions / 2 - 1, 0, this.numDivisions - 1, halfSizeX - (this.sizeX / this.numDivisions) * 0.5, -halfSizeX + (this.sizeX / this.numDivisions) * 0.5), THREE.MathUtils.mapLinear(this.numDivisions / 2 - 1, 0, this.numDivisions - 1, halfSizeY - (this.sizeY / this.numDivisions) * 0.5, -halfSizeY + (this.sizeY / this.numDivisions) * 0.5));
      offset = new THREE.Vector2(this.sizeX / this.numDivisions, this.sizeY / this.numDivisions);
      offset.applyMatrix3(this.transformMatrix);
      this.newBufferUVs(geometry, offset);

      //this.bufferUVs(geometry, this.numDivisions);

      // @ts-ignore
      this.backgroundMesh = new THREE.Mesh(<THREE.BufferGeometry> geometry, material);
      //this.backgroundMesh.position.y = -window.innerHeight / 6;
      this.backgroundMesh.renderOrder = -this.index;
      //this.backgroundMesh.position.z = -this.index * 0.001;

      scene.add(this.backgroundMesh) ;
    }

    private bufferUVs(geometry: any, count: number) {

      const uvBuffer = geometry.createAttribute('uv', 2).array;
      const uvs = geometry.prefabGeometry.attributes.uv.array;
  
      var offsetPos = 1 - 1 / count;

      for (let i = 0, offset = 0; i < geometry.prefabCount; i++, offset += 8) {
        //for (let j = 0; j < geometry.prefabVertexCount; j++, offset += 8) {
          //uvBuffer[offset    ] = uvs[j * 2];
          //uvBuffer[offset + 1] = uvs[j * 2 + 1];
          var xpos = i % count * (1 / count);
          var ypos = Math.trunc(i / count) * (1 / count);
          uvBuffer[offset    ] = offsetPos  - xpos;
          uvBuffer[offset + 1] = offsetPos  - ypos + 1 / count;
          uvBuffer[offset + 2] = offsetPos  - xpos + 1 / count;
          uvBuffer[offset + 3] = offsetPos  - ypos + 1 / count;
          uvBuffer[offset + 4] = offsetPos  - xpos;
          uvBuffer[offset + 5] = offsetPos  - ypos;
          uvBuffer[offset + 6] = offsetPos  - xpos + 1 / count;
          uvBuffer[offset + 7] = offsetPos  - ypos;
        //}
      }
      

    }

    private newBufferUVs(geometry: any, offset: THREE.Vector2) {
      //let coords = [];
      //coords.length = 2 * geometry.attributes.position.array.length / 3;
      const coords = geometry.createAttribute('uv', 2).array;

      let minVector = new THREE.Vector3(-this.sizeX / 2, -this.sizeY / 2, 0);
      let maxVector = new THREE.Vector3(this.sizeX / 2, this.sizeY / 2, 0);
      let box = new THREE.Box3(minVector, maxVector);
      let centerPos = new THREE.Vector3(0., 0.);
      let boxSize = box.getSize(centerPos);

      for (let vi = 0; vi < geometry.attributes.position.array.length; vi += 12) {
        let vx0 = geometry.attributes.startPosition.array[vi];
        let vy0 = geometry.attributes.startPosition.array[vi + 1];
        let vz0 = geometry.attributes.startPosition.array[vi + 2];
  
        let vx1 = geometry.attributes.startPosition.array[vi + 3];
        let vy1 = geometry.attributes.startPosition.array[vi + 4];
        let vz1 = geometry.attributes.startPosition.array[vi + 5];
  
        let vx2 = geometry.attributes.startPosition.array[vi + 6];
        let vy2 = geometry.attributes.startPosition.array[vi + 7];
        let vz2 = geometry.attributes.startPosition.array[vi + 8];

        let vx3 = geometry.attributes.startPosition.array[vi + 9];
        let vy3 = geometry.attributes.startPosition.array[vi + 10];
        let vz3 = geometry.attributes.startPosition.array[vi + 11];
  
        let v0 = new THREE.Vector3(vx0, vy0, vz0);
        let v1 = new THREE.Vector3(vx1, vy1, vz1);
        let v2 = new THREE.Vector3(vx2, vy2, vz2);
        let v3 = new THREE.Vector3(vx3, vy3, vz3);
  
        let uvs = makeUVs(v0, v1, v2, v3, this.transformMatrix, box, boxSize, offset);
  
        let idx0 = vi / 1.5;
        let idx1 = idx0 + 2;
        let idx2 = idx0 + 4;
        let idx3 = idx0 + 6;
  
        coords[idx0] = uvs.uv0.x;
        coords[idx0 + 1] = uvs.uv0.y;
  
        coords[idx1] = uvs.uv1.x;
        coords[idx1 + 1] = uvs.uv1.y;
  
        coords[idx2] = uvs.uv2.x;
        coords[idx2 + 1] = uvs.uv2.y;

        coords[idx3] = uvs.uv3.x;
        coords[idx3 + 1] = uvs.uv3.y;
      }
    }

    private setCamera(camera: THREE.PerspectiveCamera) {
      let dist = camera.position.z - this.backgroundMesh.position.z;
      camera.position.y = -window.innerHeight / 3;
      let height = window.innerHeight;
      camera.fov = 2 * Math.atan((height  ) / (2 * dist)) * (180 / Math.PI);
      camera.updateProjectionMatrix();
    }

    public update(secs: number, position: number): void {
      this.uTime.value = secs;
      this.uProgress.value = (this.section._tl as gsap.core.Timeline).progress();

      this.uChangedPosition.value = position - this.sizeY / 2;
    }
}