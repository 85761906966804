/*
 * App.ts
 * ===========
 */
import Splide from "@splidejs/splide";
import { gsap } from "gsap";
//import { gsap } from "./js/vendor/gsap";
//import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { VideoTexture } from "three";
import THREE = require("three");
import { AssetsStore, getAssets, HTMLExt, isMobile, PageData } from "Utils";
import AnimateScroll from "./motion/AnimateScroll";
import { Cursor } from "./motion/Cursor";
import { HorizontalScroll } from "./motion/HorizontalScroll";
import { InteractiveHeader } from "./motion/InteractiveHeader";
import { MagneticButton } from "./motion/MagneticButton";
import { SmoothScroll } from "./motion/SmoothScroll";
import BackgroundView from "./webgl/BackgroundView";

class App {
    // general
    private sharedData: PageData[];
    private animateScroll: AnimateScroll;
    // webgl
    public assets: AssetsStore;
    private backgroundView: BackgroundView;
    // motion
    public scrollTrigger: gsap.plugins.ScrollTrigger;
    private smoothScroll: SmoothScroll;
    private horizontalScroll: HorizontalScroll;
    private interHeader: InteractiveHeader;
    private cursor: Cursor;
    private mouseEv: MouseEvent;
    private button: MagneticButton;
    private buttons: MagneticButton[];
    private isMobile: boolean

    constructor() {
        // fix scroll
     
        // motion
        const scTrigger = ScrollTrigger;
        const scToPlugin = ScrollToPlugin;
        gsap.registerPlugin(scTrigger, scToPlugin);
        console.log(gsap.version);
        console.log(scTrigger.version);
        this.scrollTrigger = scTrigger;
        this.smoothScroll = new SmoothScroll(this.scrollTrigger);  
        this.horizontalScroll = new HorizontalScroll(this.scrollTrigger, this.smoothScroll.locoScroll);
        this.animateScroll = new AnimateScroll(this.scrollTrigger, this.smoothScroll.locoScroll);
        this.interHeader = new InteractiveHeader(this.smoothScroll.locoScroll);
        this.cursor = new Cursor(document.querySelector('.cursor'));
        window.addEventListener('mousemove', ev => this.mouseEv = ev);
        //this.button = new MagneticButton(document.querySelector('.button-test'));
        //this.button.on('enter', (el) => this.cursor.enter(el));
        //this.button.on('leave', (el) => this.cursor.leave(el));
        this.buttons = [];
        document.querySelectorAll<HTMLElement>('.button-test').forEach(el => this.buttons.push(new MagneticButton(el)))
        this.buttons.forEach(button => {        
            button.on('enter', (el) => this.cursor.enter(el));
            button.on('leave', (el) => this.cursor.leave(el));
        });     

        
        // get shared page elements
        this.sharedData = [];
        let sections = gsap.utils.toArray<HTMLElement & HTMLExt>("section");
        sections.forEach(s => {
            this.sharedData.push({section: s, elem: s.querySelector<HTMLElement>('[data-fxview]'), ctx: null, fn: null});
        });

        // Enable modal form
        document.querySelector<HTMLElement>(".modal-button").addEventListener('click', function() {
            document.querySelector<HTMLElement>(".modal-form").classList.add('modal-open');
          }); 
        document.querySelectorAll<HTMLElement>(".close-btn, .bg-overlay").forEach(el =>
            { el.addEventListener('click', function(){
                document.querySelector<HTMLElement>(".modal-form").classList.remove('modal-open');    
            })
        }); 
        let mobile = isMobile();
        this.isMobile = mobile;
        if (!this.isMobile) {
            const video = <HTMLVideoElement>(
                document.getElementById("video-bg")
            );
            video.style.display = 'none';
            const canvasBox = <HTMLCanvasElement>(
                document.getElementById("webgl-canvas")
            );
            canvasBox.width = window.innerWidth;
            canvasBox.height = window.innerHeight;
            const assets = new AssetsStore();
            this.assets = assets;
    
            this.backgroundView = new BackgroundView(canvasBox, this.assets);
    
            this.loadAssets();
        }
        else {
            const canvasBox = <HTMLCanvasElement>(
                document.getElementById("webgl-canvas")
            );
            canvasBox.style.display = 'none';
            this.prepareVideo();
        }
    }

    private init() {
        // set slider
        new Splide( '#partners-slider', {
            type        : 'loop',
            autoplay    : true,    
            interval    : 1000,
            autoWidth   : true,
            height      : 100,
            gap         : 80,
            focus       : 'center',
            pagination  : false,
            } ).mount();
        this.horizontalScroll.init();
        this.animateScroll.init(this.sharedData);
        if (!this.isMobile) {
            this.backgroundView.init(this.sharedData);
        }
        this.buttons.forEach(button => button.init());
    }

    private update = (t: number): void => {
        if (typeof this.mouseEv !== 'undefined' && this.mouseEv != null) {
            this.cursor.update(this.mouseEv);
        }

        this.backgroundView.update(t / 1000);
        requestAnimationFrame(this.update);
    }

    private async loadAssets() {
        //return new Promise((resolve, reject) => {
        //setTimeout(() => {
        console.log("Start load");
        const manager = new THREE.LoadingManager();
        manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
            console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );            
        };            
        manager.onLoad = function ( ) {            
            console.log( 'Loading complete!');            
        };    
        manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {            
            console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );            
        };            
        manager.onError = function ( url ) {            
            console.log( 'There was an error loading ' + url );            
        };

        var loader = new THREE.TextureLoader(manager);

        document.querySelectorAll<HTMLElement>('[data-fxview]').forEach((elem) => {
            var sceneName = elem.dataset.fxview;
            if (sceneName == 'first') {
            this.assets.videos[sceneName] = {
                name: sceneName
            };
            } else {
            this.assets.textures[sceneName] = {
                name: sceneName
                };
            }
            var section = this.sharedData.find(x => x.elem === elem)?.section;
            var index = Array.prototype.indexOf.call(section.parentElement.children, section);
            //this.loadTexture(manager, sceneName, index);
            //getAssets(loader,  this.assets, (progress) => console.log(progress));
        });

        const results = await Promise.all(getAssets(loader,  this.assets, (progress) => {console.log(progress)}));
        console.log(results);
        console.log("loaded");
        // Turn off preloader
        document.querySelector<HTMLElement>('body').classList.add('loaded');

        this.init();
        this.update(0)
        
        //let promises = getAssets(loader,  this.assets, (progress) => {console.log(progress)})
        //.map(p => p.catch(e => e));            
        //await Promise.all(promises)
        //.then(textures => {
        //    console.log(textures);
        //    console.log(this.assets);
        //    console.log("loaded");
            // Turn off preloader
        //    document.querySelector<HTMLElement>('body').classList.add('loaded');
        //    this.init();
        //    this.update(0)
        //  })
        //  .catch((err) => {
        //    console.log(err);
        //  })
        //manager.itemStart("a");
        //manager.itemEnd("a");
        //}, 1500);
    // });
    };

    private async prepareVideo()
    {
        const video = <HTMLVideoElement>(
            document.getElementById("video-bg")
        );
        video.autoplay = true;

        const result = await this.loadVideo(video);

        console.log(result);
        console.log("loaded");
        // Turn off preloader
        document.querySelector<HTMLElement>('body').classList.add('loaded');

        this.init();
        if (!this.isMobile) {
            this.update(0)
        }
    }

    private loadVideo(video: HTMLVideoElement) {
        return new Promise((resolve,reject) => {
            function onCanPlay() {
                video.removeEventListener('canplaythrough', onCanPlay, false);
                video.removeEventListener('load', onCanPlay, false);
                video.removeEventListener('loadeddata', onCanPlay, false);
                //video is ready
                video.play();
                const videoTexture = new THREE.VideoTexture( video );
                console.log('video is loaded')
                resolve('video is loaded') ;
            }

            video.play(); //start loading, didn't used `vid.load()` since it causes problems with the `ended` event

            if(video.readyState !== 4){ //HAVE_ENOUGH_DATA
                video.addEventListener('canplaythrough', onCanPlay, false);
                video.addEventListener('load', onCanPlay, false); //add load event as well to avoid errors, sometimes 'canplaythrough' won't dispatch.
                video.addEventListener('loadeddata', onCanPlay, false);
                setTimeout(function(){
                    video.pause(); //block play so it buffers before playing
                }, 1); //it needs to be after a delay otherwise it doesn't work properly.
            }else{
                //video is ready
                video.play();
                const videoTexture = new THREE.VideoTexture( video );
                console.log('video was already prepared')
                resolve('video was already prepared') ;
            }                    
        }   
      );
    }

    private loadTexture(manager: THREE.LoadingManager, sceneName: string, index: number) {
        if (sceneName == 'first') {
            //manager.itemEnd("VideoTexture");

            let onVideoLoad = () => {

                video.removeEventListener( 'loadedmetadata', onVideoLoad, false );
                //video.play();
                const texture = new THREE.VideoTexture( video );
                this.assets.textures[sceneName].texture = texture;
                manager.itemEnd( "assets/dubai_night.mp4" ); // notifying about end of loading process
                

            }

            const video = document.createElementNS( 'http://www.w3.org/1999/xhtml', 'video' ) as HTMLVideoElement;
            video.addEventListener( 'loadedmetadata',  onVideoLoad, false );

            video.src = "assets/dubai_night.mp4";
            video.preload = "auto";

            video.setAttribute( 'webkit-playsinline', 'webkit-playsinline' );
            video.setAttribute( 'playsinline', '' );

            manager.itemStart( "assets/dubai_night.mp4" ); // notifying about start of loading process


        } else {
            var loader = new THREE.TextureLoader(manager);
            loader.load(`https://picsum.photos/2048/2048?random=${index}`, (texture) =>{ 
            this.assets.textures[sceneName].texture = texture;
            });
        }
    }
}

const app = new App();