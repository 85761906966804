import LocomotiveScroll from 'locomotive-scroll';
import { isEmpty, isMobile } from 'Utils';

export class SmoothScroll {

    public locoScroll: any;

    constructor(scrollTrigger: gsap.plugins.ScrollTrigger) {
        // Using Locomotive Scroll from Locomotive https://github.com/locomotivemtl/locomotive-scroll
        let locoScroll: any = {}
        locoScroll = new LocomotiveScroll({
            el: document.querySelector(".smooth-scroll"),
            smooth: true,
            getDirection: true
            });

        this.locoScroll = locoScroll;
        // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
        locoScroll.on("scroll", scrollTrigger.update);
        //locoScroll.on("scroll", (obj) => {
        //    console.log(obj);
        //    scrollTrigger.update;
        //});
        let mobile = isMobile();
        let scroller = document.querySelector('[data-scroll-section]') ? (document.querySelector('[data-scroll-section]') as HTMLElement) : (document.querySelector('.smooth-scroll') as HTMLElement);
        let isIOS = /iPad|iPhone|iPod/.test(navigator.platform)
        || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
        scrollTrigger.scrollerProxy(".smooth-scroll", {
            scrollTop(value) {
            return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
            }, // we don't have to define a scrollLeft because we're only scrolling vertically.
            getBoundingClientRect() {
            return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
            },
            // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
            //pinType: (document.querySelector(".smooth-scroll") as HTMLElement).style.transform ? "transform" : "fixed"
            pinType: scroller.style.transform ? "transform" : "fixed"
            //pinType: scroller.style.transform || isIOS ? "transform" : "fixed"
            //pinType: mobile ? "fixed" : "transform"
        });

        // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
        scrollTrigger.addEventListener("refresh", () => locoScroll.update());

        // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
        scrollTrigger.refresh();
    }

}