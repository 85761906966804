import {gsap} from 'gsap';
import { EventEmitter } from 'events';
import { lerp, getMousePos, calcWinsize, distance, getBoundingRect, distanceToBorder, uid } from '../utils';

// Calculate the viewport size
let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

// Track the mouse position
let mousepos = {x: 0, y: 0};
window.addEventListener('mousemove', ev => mousepos = getMousePos(ev));

export class MagneticButton extends EventEmitter {
    private DOM: { el: HTMLElement; overlay?: HTMLElement; svg?: SVGSVGElement, gradient?: SVGRadialGradientElement, text?: HTMLElement; textinner?: HTMLElement; nav?: HTMLElement };
    private renderedStyles: { tx: { previous: number; current: number; amt: number; }; ty: { previous: number; current: number; amt: number; }; };
    private state: { hover: boolean; hidden: boolean };
    private rectangle: DOMRect;
    private distanceToTrigger: number;
    private svgWidth: number;
    private svgOffset: number;

    constructor(el: HTMLElement) {
        super();
        // DOM elements
        // el: main button
        // text/textinner: inner text elements
        this.DOM = {el: el};
        this.DOM.text = this.DOM.el.querySelector('.button__text');
        this.DOM.textinner = this.DOM.el.querySelector('.button__text-inner');  
        this.DOM.overlay = this.DOM.el.querySelector('.overlay');
        this.addFalcon();
        this.DOM.svg = this.DOM.el.querySelector('svg');
        this.DOM.gradient = el.querySelector('radialGradient');
        // amounts the button will translate
        this.renderedStyles = {
            tx: {previous: 0, current: 0, amt: 0.1},
            ty: {previous: 0, current: 0, amt: 0.1}
        };
        // button state (hover)
        this.state = {
            hover: false,
            hidden: false
        };
        

        // init events
        this.initEvents();
        // loop fn
        //requestAnimationFrame(() => this.render());
    }
    calculateSizePosition() {
        // size/position
        this.rectangle = getBoundingRect(this.DOM.el);
        // the movement will take place when the distance from the mouse to the center of the button is lower than this value
        //this.distanceToTrigger = this.rect.width*0.7;
        this.distanceToTrigger = 8;

        let svgRatio = this.DOM.svg.viewBox.baseVal.width / this.DOM.svg.viewBox.baseVal.height;
        this.svgWidth = this.DOM.el.clientHeight * svgRatio;
        this.svgOffset = (this.rectangle.width - this.svgWidth) / 2;
    }
    initEvents() {
        
        window.addEventListener('resize', this.onResize);
    }

    public init(){
        // calculate size/position
        this.calculateSizePosition();
        // loop fn
        requestAnimationFrame(() => this.render());
    }

    public isHovered = () => this.state.hover;

    private onResize = () => this.calculateSizePosition();

    public render() {
        // calculate the distance from the mouse to the center of the button
        //const distanceMouseButton = distance(mousepos.x, mousepos.y, this.rect.left + this.rect.width/2, this.rect.top + this.rect.height/2);
        const distanceMouseButton = distanceToBorder(mousepos.x, mousepos.y, this.rectangle);
        // new values for the translations
        let x = 0;
        let y = 0;

        if ( distanceMouseButton < this.distanceToTrigger ) {
            if ( !this.state.hover ) {
                this.enter();
            }
            //x = (mousepos.x - (this.rect.left + this.rect.width/2))*.3;
            //y = (mousepos.y - (this.rect.top + this.rect.height/2))*.3;
            x = (mousepos.x - (this.rectangle.left + this.rectangle.width/2))*.1;
            y = (mousepos.y - (this.rectangle.top + this.rectangle.height/2))*.1;
        }
        else if ( this.state.hover ) {
            this.leave();
        }

        this.renderedStyles['tx'].current = x;
        this.renderedStyles['ty'].current = y;
        
        for (const key in this.renderedStyles ) {
            this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
        }

        this.DOM.el.style.transform = `translate3d(${this.renderedStyles['tx'].previous}px, ${this.renderedStyles['ty'].previous}px, 0)`;
        this.DOM.text.style.transform = `translate3d(${-this.renderedStyles['tx'].previous*0.6}px, ${-this.renderedStyles['ty'].previous*0.6}px, 0)`;

        if (this.isHovered()) {
            gsap.to(this.DOM.overlay, {
                duration: 0.1, 
            backgroundImage: `radial-gradient(circle at ${
                mousepos.x - this.rectangle.left
            }px ${
                mousepos.y - this.rectangle.top
            }px, rgba(255,255,255,0.2), 50%, rgba(255,255,255,0.0))`
            }); 
            gsap.to(this.DOM.gradient, {
                duration: 0.1, 
                attr: { cx: `${
                    (mousepos.x  - (this.rectangle.left + this.DOM.el.clientWidth / 2 ) - x) * ( this.DOM.el.clientWidth / this.svgWidth  ) / this.DOM.el.clientWidth * 100 + 50 + 9
                  }%`,
                  cy: `${
                    (mousepos.y - this.rectangle.top - y) / this.DOM.el.clientHeight * 100 + 16
                  }%` }
            });
        }

        requestAnimationFrame(() => this.render());
    }
    enter() {
        if (!( this.DOM.el.offsetWidth || this.DOM.el.offsetHeight || this.DOM.el.getClientRects().length ) || window.innerWidth < 768 || window.getComputedStyle(this.DOM.el).visibility === "hidden" ) {
            return;
        }
        this.emit('enter', this.DOM.el);

        this.state.hover = true;
        this.DOM.el.classList.add('button--hover');

        document.body.classList.add('active');
        let borderRadius = window.getComputedStyle(this.DOM.el).borderRadius;

        gsap.killTweensOf(this.DOM.textinner);
        gsap.killTweensOf(this.DOM.overlay);
        gsap.killTweensOf(this.DOM.svg);

        // set default state
        gsap.to(this.DOM.overlay, {
            duration: 0.0, 
            borderRadius: "50%",
            left: mousepos.x - this.rectangle.left - this.rectangle.width /2,
            top: mousepos.y - this.rectangle.top - this.rectangle.height /2,
            scale: 0.0
        });

        gsap.to(this.DOM.svg, {
            duration: 0.0, 
            left: mousepos.x - this.rectangle.left,
            top: mousepos.y - this.rectangle.top,
            scale: 0.0
        });
        
        // animation
        gsap
        .timeline()
        .to(this.DOM.textinner, {
            duration: 0.15,
            ease: 'Power2.easeIn',
            opacity: 0, 
            y: '-20%'
        })
        .to(this.DOM.textinner, {
            duration: 0.2,
            ease: 'Expo.easeOut',
            opacity: 1, 
            startAt: {y: '100%'}, 
            y: '0%'
        });
        gsap.to(this.DOM.overlay, {
            duration: 0.3, 
            borderRadius: borderRadius,
            opacity: 0.8,
            scale: 1,
            left: -5,
            top: -5
        });
        gsap.to(this.DOM.svg, {
            duration: 0.3, 
            opacity: 1,
            width: this.svgWidth,
            height: this.DOM.el.clientHeight,
            scale: 1,
            left: this.svgOffset,
            top: 0
        });
    }
    leave() {
        this.emit('leave', this.DOM.el);
        
        this.state.hover = false;
        this.DOM.el.classList.remove('button--hover');
        
        document.body.classList.remove('active');

        gsap.killTweensOf(this.DOM.textinner);
        gsap.killTweensOf(this.DOM.overlay);
        gsap.killTweensOf(this.DOM.svg);
        
        gsap
        .timeline()
        .to(this.DOM.textinner, {
            duration: 0.15,
            ease: 'Power2.easeIn',
            opacity: 0, 
            y: '20%'
        })
        .to(this.DOM.textinner, {
            duration: 0.2,
            ease: 'Expo.easeOut',
            opacity: 1, 
            startAt: {y: '-100%'}, 
            y: '0%'
        });

        gsap.to(this.DOM.overlay, {
            duration: 0.4, 
            borderRadius: "50%",
            opacity: 0.0,
            left: mousepos.x - this.rectangle.left - this.rectangle.width /2,
            top: mousepos.y - this.rectangle.top - this.rectangle.height /2,
            scale: 0.0
        });;
        gsap.to(this.DOM.svg, {
            duration: 0.4, 
            opacity: 0,
            width: 0,
            height: 0,
            left: mousepos.x - this.rectangle.left,
            top: mousepos.y - this.rectangle.top,
            scale: 0.0
        });

    }

    addFalcon() {
        var id = uid();
        var newFalcon = `<svg id="button_${id}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" x="100" y="0" width="25"  height="25" class="svg-content" style="opacity: 0;"  viewBox="0 0 504 174">
        <defs>
        <style>
            .cls-${id} {
            fill: url(#gradient_${id});
            }
        </style>
        <radialGradient id="gradient_${id}" data-name="gradient 1" cx="298" cy="118" r="65%" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#daa520" stop-opacity="0.8"/>
            <stop offset="1" stop-color="#c49c48" stop-opacity="0.45"/>
        </radialGradient>
        </defs>
        <path id="falcon" class="cls-${id}" d="M360.74,205H235.26v-4H360.74ZM209.7,87.67H76.37v4H209.7Zm309.93,0H386.3v4H519.63Zm-174.22,0H250.59v4h94.82ZM219.63,59.33H61.56v4H219.63Zm314.81,0H376.37v4H534.44Zm-213.63,2-1.18-2H276.37v4h43.26ZM91.48,31H45.81v4H91.48Zm458.71,0H504.52v4h45.67Zm-239.6,0H270.15v4h40.44ZM221,116H104.74v4H221Zm270.22,0H375v4h116.3Zm-132.67,0H237.41v4H358.59Zm118.82,14.17H118.59v4H477.41ZM337.7,186.83H258.3v4h79.4Zm-127.26-85H84.07v4H210.44Zm301.49,0H385.56v4H511.93Zm-154.08,0H238.15v4h119.7ZM216.67,73.5H68.07v4h148.6Zm311.26,0H379.33v4h148.6Zm-203.26,0H271.33v4h53.34ZM137.11,45.17H54v4h83.11Zm404.89,0H458.89v4H542Zm-226.07,0H256v4h59.93Zm148.7,99.16H131.37v4H464.63ZM226.3,172.67H172.81v4H226.3Zm119.11,0H250.59v4h94.82Zm77.78,0H369.7v4h53.49Zm24.22-14.17H148.59v4H447.41Z" transform="translate(-45.81 -31)"/>
    </svg>`;
        this.DOM.el.insertAdjacentHTML('beforeend', newFalcon);
      }
}